import { BASE_ROUTE } from "../../utils/routing";

interface IExternalAuthorization {
  news: boolean;
  oncology: boolean
  trending: boolean;
  newsCurator: boolean;
  kiqBuilder: boolean;
  avCompany: boolean;
  kiq: boolean;
  topics: boolean;
  products: boolean;
  companies: boolean;
  peerBigSheet: boolean;
  earnings: boolean;
  experimentalPage: boolean;
}

interface IExternalAuthorizationResponseObject {
  externalAuthorization: IExternalAuthorization;
}

export interface IMegaMenuSection {
  sectionLabel: string;
  links: {
    label: string;
    url: string;
    isDisabled?: boolean;
  }[];
}
interface IMegaMenuSectionConfig {
  sectionLabel: string;
  links: {
    property: keyof IExternalAuthorization;
    label: string;
    urlPath: string;
  }[];
}

export class ExternalMegaMenuUtility {
  constructor() {}
  private authorizationQuery = `
          externalAuthorization {
            news
            trending
            newsCurator
            kiqBuilder
            avCompany
            kiq
            topics
            products
            companies
            peerBigSheet
            earnings
            experimentalPage
            oncology
          }
  `;
  private authorizationQueryKeys = ["externalAuthorization"];
  private megaMenuConfig: IMegaMenuSectionConfig[] = [
    {
      sectionLabel: "IN THE NEWS",
      links: [
        { property: "trending", label: "Trending News", urlPath: "" },
        { property: "news", label: "News Article Search", urlPath: "/news-search" },
        { property: "products", label: "Products", urlPath: "/products" },
        { property: "topics", label: "Topics", urlPath: "/topics" },
      ],
    },
    {
      sectionLabel: "CUSTOMIZED NEWS",
      links: [
        {
          property: "newsCurator",
          label: "News Curator",
          urlPath: "/news-curator",
        },
        {
          property: "kiqBuilder",
          label: "Key Intelligence Question Builder",
          urlPath: "/builder",
        },
      ],
    },
    {
      sectionLabel: "INSIGHTS",
      links: [
        {
          property: "avCompany",
          label: "Amgen Ventures",
          urlPath: "/amgen-ventures",
        },
        {
          property: "oncology",
          label: "Oncology",
          urlPath: "/ta/oncology",
        },
        { property: "kiq", label: "Corporate Strategy", urlPath: "/corporate-strategy" },
      ],
    },
    {
      sectionLabel: "FINANCIALS",
      links: [
        {
          property: "earnings",
          label: "Earnings",
          urlPath: "/earnings",
        },
        {
          property: "companies",
          label: "Company Financials",
          urlPath: "/companies",
        },
        {
          property: "peerBigSheet",
          label: "Peer Big Sheet",
          urlPath: "/peer-big-sheet",
        },
      ],
    },
    {
      sectionLabel: "EXPERIMENTAL",
      links: [
        {
          property: "experimentalPage",
          label: "Experimental Features",
          urlPath: "/experimental",
        },
      ],
    },
  ];
  private basePath = BASE_ROUTE;
  workstream = "external";

  getAuthorizationQuery() {
    return this.authorizationQuery;
  }

  getAuthorizationQueryKeys() {
    return this.authorizationQueryKeys;
  }

  generateMegaMenuSections(
    response: IExternalAuthorizationResponseObject
  ): IMegaMenuSection[] {
    const { externalAuthorization } = response;
    return this.megaMenuConfig.reduce((sections, config) => {
      const sectionLinks = config.links
        .filter((link) => externalAuthorization[link.property])
        .map((link) => ({
          label: link.label,
          url: `${this.basePath}${link.urlPath}`,
        }));

      if (sectionLinks.length > 0) {
        sections.push({
          sectionLabel: config.sectionLabel,
          links: sectionLinks,
        });
      }

      return sections;
    }, [] as IMegaMenuSection[]);
  }
}
